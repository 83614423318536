import React, { useState } from 'react'
import ContentImages from '@solid-ui-components/ContentImages'
import './Video.css'

const Video = ({ content: { images }, reverse, videoSrcURL, videoTitle }) => {
  const [play, setPlay] = useState(false)

  const togglePlay = () => {
    setPlay(current => !current)
  }

  if (!play) {
    return (
      <>
        <div
          className='videoThumbnail'
          style={{ position: 'relative' }}
          onClick={togglePlay}
        >
          <ContentImages
            content={{ images }}
            loading='eager'
            reverse={reverse}
            imagePosition='center'
            isVideoThumbnail={true}
          />
          <span className='videoThumbnailDescription'>
            - Click to Play video -
          </span>
        </div>
      </>
    )
  }

  return (
    <div
      className='video'
      style={{ borderRadius: '16px', overflow: 'hidden', display: 'flex' }}
    >
      <iframe
        style={{ aspectRatio: '1123.11/631.55' }}
        src={videoSrcURL}
        title={videoTitle}
        allow='autoplay; accelerometer; encrypted-media; gyroscope; picture-in-picture'
        frameBorder='0'
        webkitallowfullscreen='true'
        mozallowfullscreen='true'
        allowFullScreen
        width='100%'
      />
    </div>
  )
}

export default Video
