import React, { useState } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { Container, Box, Card } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ListItem from '@solid-ui-components/ListItem'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import './CodeTabs.css'
// import csharp from 'react-syntax-highlighter/dist/esm/languages/hljs/csharp';
// import dart from 'react-syntax-highlighter/dist/esm/languages/hljs/dart';
// import js from 'react-syntax-highlighter/dist/esm/languages/hljs/javascript';
// import html from 'react-syntax-highlighter/dist/esm/languages/hljs/htmlbars';
import theme from 'react-syntax-highlighter/dist/esm/styles/prism/material-dark'

// SyntaxHighlighter.registerLanguage('csharp', csharp);
// SyntaxHighlighter.registerLanguage('dart', dart);
// SyntaxHighlighter.registerLanguage('javascript', js);
// SyntaxHighlighter.registerLanguage('html', html);

const CodeTabs = ({ content: { text, collection } }) => {
  const style = ``

  const [tabIndex, setTabIndex] = useState(0)

  return (
    <>
      <style>{style}</style>
      <Container sx={{ textAlign: `center` }}>
        <Box>
          <Reveal effect='fadeInDown'>
            <ContentText content={text} />
          </Reveal>
        </Box>
        <Divider space={3} />
        <Tabs
          className='code-tabs'
          selectedIndex={tabIndex}
          onSelect={index => setTabIndex(index)}
        >
          <TabList>
            {collection.map(({ text }, i) => (
              <Tab key={i}>
                <Card
                  py='3'
                  m='1'
                  className={tabIndex === i ? 'code-tab active' : 'code-tab'}
                >
                  <ListItem text={text?.[1]} compact middle p='2' />
                </Card>
              </Tab>
            ))}
          </TabList>

          {collection.map(({ text }, i) => (
            <TabPanel
              key={i}
              style={{
                display: tabIndex === i ? 'block' : 'none',
                margin: '0 auto'
              }}
            >
              <SyntaxHighlighter
                language={text?.[0].text}
                style={theme}
                showLineNumbers={true}
                wrapLines={true}
              >
                {text?.[2].text}
              </SyntaxHighlighter>
            </TabPanel>
          ))}
        </Tabs>
      </Container>
    </>
  )
}

export default CodeTabs
